<template>
  <layout>
    <template #secondarySidebar>
      <scheduler-nav />
    </template>

    <alert-response-warning v-if="state.hasError" :error="state.accessError" />

    <div v-if="!state.hasError" class="flex flex-col sm:flex-row sm:justify-between sm:items-center border-b pb-2">
      <h2 class="font-bold text-xl sm:text-2xl">
        Machine Ice Makers
      </h2>
      <text-input type="search" v-model="state.search" placeholder="Search..." class="w-full sm:w-64 lg:w-96" />
    </div>

    <template v-if="!state.hasError" class="">
      <div class="relative space-y-6 xl:space-y-8 mt-6 xl:mt-8">
        <div v-if="state.isLoading" class="absolute inset-0 flex items-center justify-center bg-white">
          <icon-loading class="w-6 h-6 text-big-stone" />
        </div>
        <template v-for="(makersState, index) in filteredMachineStates" :key="index">
          <!-- <a :name="`${makersState.location_id}`"></a> -->

          <div class="bg-white rounded-md overflow-hidden shadow-sm">

            <div class="bg-blumine p-4 flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <h2 class="text-white md:text-lg" v-html="highlightInString(makersState.name, state.search)" />
              <div class="text-sm text-white">
                <span class="font-semibold">Machine Time:</span> {{ state.times[index] }}
              </div>
            </div>

            <div class="bg-sail bg-opacity-50 px-4 py-2 flex flex-col md:flex-row md:items-center md:justify-between">
              <div v-if="makersState.schedules.length > 0" class="space-y-2">
                
                <!-- Active schedule -->
                <div
                  v-for="(schedule, sindex) in makersState.schedules" :key="sindex"
                  class="flex flex-col md:flex-row md:space-x-3 xl:space-x-2 md:items-center text-sm"
                >
                  <div class="hidden md:flex">
                    <span class="sr-only">Active schedule</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-green-600" viewBox="0 0 24 24" fill="currentColor" title="Active schedule"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z"/></svg>
                  </div>
                  <div class="flex md:flex-col xl:flex-row md:leading-none space-x-0.5 md:space-x-0 xl:space-x-0.5">
                    <span class="font-semibold">Name:</span>
                    <span>
                      {{ schedule.name }}
                    </span>
                  </div>
                  <div class="flex md:flex-col xl:flex-row md:leading-none space-x-0.5 md:space-x-0 xl:space-x-0.5">
                    <span class="font-semibold">IM:</span>
                    <span>
                      {{
                        [
                          schedule.icemaker1_enabled && 'Ice Maker 1',
                          schedule.icemaker2_enabled && 'Ice Maker 2'
                        ].filter(obj => obj).join(', ')
                      }}
                    </span>
                  </div>
                  <div class="flex md:flex-col xl:flex-row md:leading-none space-x-0.5 md:space-x-0 xl:space-x-0.5">
                    <span class="font-semibold">Time Windows:</span>
                    <span>
                      {{
                        schedule.schedule_times.map(obj => `${obj.stop} - ${obj.start}`).join(' | ')
                      }}
                    </span>
                  </div>
                  <div class="flex md:flex-col xl:flex-row md:leading-none space-x-0.5 md:space-x-0 xl:space-x-0.5">
                    <span class="font-semibold">Repeat:</span>
                    <span>
                      {{ schedule.cron_human }}
                    </span>
                  </div>
                  <div class="flex md:flex-col xl:flex-row md:leading-none space-x-0.5 md:space-x-0 xl:space-x-0.5">
                    <span class="font-semibold">During:</span>
                    <span>
                      {{ schedule.active_from_human }}
                      - {{ schedule.active_till_human }}
                    </span>
                  </div> 
                </div>
                <!-- /Active schedule -->

              </div>
              <div v-else>
                <div class="flex flex-col md:flex-row md:space-x-3 xl:space-x-2 md:items-center text-sm">
                  <div class="hidden md:flex">
                    <span class="sr-only">Active schedule</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-500" viewBox="0 0 24 24" fill="currentColor" title="Active schedule"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z"/></svg>
                  </div>
                  <div class="flex md:flex-col xl:flex-row md:leading-none space-x-0.5 md:space-x-0 xl:space-x-0.5">
                    <span>No Active Schedule</span>
                  </div>
                </div>
              </div>

              <div>
                <router-link
                  :to="{ name: 'SchedulerSchedulesIndex', query: { search: makersState.name } }"
                  class="text-sm underline mt-2 md:mt-0 md:ml-4"
                >
                  <span class="group-hover:underline">Show all Schedules ({{ makersState.schedules_count }})</span>
                </router-link>
              </div>
            </div>

            <div class="bg-concrete border-t border-sail">
              <ice-maker-status-table :makers-state="makersState" />
            </div>
          </div>
        </template>
        <div v-if="!state.isLoading && filteredMachineStates.length === 0">
          No Machines Available
        </div>
      </div>
    </template>

  </layout>
</template>

<script>
import { reactive, computed, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import http from '@/services/http.js'
import date from '@/helpers/date.js'
import cron from '@/helpers/cron.js'
import { highlightInString } from '@/helpers/utils.js'
import Layout from '@/layouts/Default.vue'
import SchedulerNav from '@/components/schedules/Nav.vue'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import TextInput from '@/components/form/Text.vue'
import { IconLoading } from '@/components/icons'
import IceMakerStatusTable from '@/components/schedules/IceMakerStatusTable.vue'

export default {
  name: 'scheduler-machines-index',

  components: {
    Layout,
    SchedulerNav,
    AlertResponseWarning,
    TextInput,
    IceMakerStatusTable,
    IconLoading,
  },

  setup() {
    // const router = useRouter()
    const route = useRoute()
    let timesInterval = null
    let pollingInterval = null
    const state = reactive({
      hasError: false,
      accessError: null,
      isLoading: true,
      search: route.query.search || '',
      machines: [],
      machineMakersStates: [],
      times: []
    })

    const getSchedules = async (isPolling = false) => {
      if (!isPolling) {
        state.isLoading = true
      }

      try {
        if (!isPolling) {
          const { data } = await http.get('user/machines')
          state.machines = data.allowed_locations
        }

        const schedulesResponse = await Promise.all(state.machines.map((machine) => {
          return http.get(`machine/${machine.location_id}/schedule`)
            .then(({ data }) => {
              return {
                ...data,
                machine,
                location_id: machine.location_id,
              }
            })
        }))

        state.machineMakersStates = schedulesResponse.reduce(
          (all, obj) => {
            let activeSchedules = obj.schedule.filter(schedule => {
              const now = date.now().tz(obj.timezone)
              const from = date.dateFormatFromApi(schedule.active_from, null).tz(obj.timezone).year(now.year())
              let till = date.dateFormatFromApi(schedule.active_till, null).tz(obj.timezone)
              till = till.year((till.month() < from.month()) ? from.year()+1 : from.year())
              return date.isBetween(now, from, till)
            }).map(activeSchedule => {
              return {
                ...activeSchedule,
                cron_human: cron.humanize(activeSchedule.cron),
                active_from_human: date.dateFormatFromApi(activeSchedule.active_from),
                active_till_human: date.dateFormatFromApi(activeSchedule.active_till),
                schedule_times: activeSchedule.schedule_times.map((times) => {
                  return {
                    stop: date.timeFormat(times.stop).fromApi(),
                    start: date.timeFormat(times.start).fromApi(),
                  }
                })
              }
            })

            all.push({
              name: obj.machine.name,
              location_id: obj.location_id,
              can_edit: obj.can_edit,
              timezone: obj.timezone,

              im_1_running: obj.im_1_running,
              im_2_running: obj.im_2_running,
              im_1_vends_since_off: obj.im_1_vends_since_off,
              im_2_vends_since_off: obj.im_2_vends_since_off,
              override: obj.override,

              im_1_action_id: 'im1',
              im_2_action_id: 'im2',
              schedules: activeSchedules,
              schedules_count: obj.schedule.length
            })
            return all
          },
          []
        )
        updateCurrentTime()
      } catch (e) {
        state.hasError = true
        state.accessError = e
      }

      state.isLoading = false
    }

    const startPolling = () => {
      pollingInterval = setInterval(() => {
        getSchedules(true)
      }, 10000)
    }

    // Machine Times
    const updateCurrentTime = () => {
      const times = []
      for (let i in state.machineMakersStates) {
        times[i] = date.now().tz(state.machineMakersStates[i].timezone).format('D. MMM, hh:mm A')
      }
      state.times = times
    }

    const startTimeUpdate = () => {
      timesInterval = setInterval(() => {
        updateCurrentTime()
      }, 1000)
      updateCurrentTime()
    }
    // /Machine Times

    const filteredMachineStates = computed(() => {
      if (state.search === '') return state.machineMakersStates

      return state.machineMakersStates.filter((item) => {
        return item.name.toLowerCase().includes(state.search.toLowerCase())
          || String(item.location_id) === state.search
      })
    })

    getSchedules()
    startPolling()
    startTimeUpdate()

    onBeforeUnmount(() => {
      clearInterval(timesInterval)
      timesInterval = null
      clearInterval(pollingInterval)
      pollingInterval = null
    })

    return {
      filteredMachineStates,
      state,
      highlightInString,
    }
  }
}
</script>
